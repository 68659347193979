<template>
	<Toast />
	<Utils ref='Utils' />
	<input hidden="true" title='打开要导入文件' class="newFile" id='choose_file' type="file" />
	<selectClassCard @cancelTag='cancelTag' @confirmTag='confirmTag' ref='slectClassCard'></selectClassCard>
	<iframe v-show='false' ref='save_file' src="saveFile/saveFile.html" frameborder="0"
		style="width: 100%;height:100px;"></iframe>
	<div class="p-grid" style="margin-bottom: -30px;">
		<Splitter :style="all_style" class="mb-5">
			<SplitterPanel id='text_content_container' :size="65" :minSize="10" style="overflow: scroll;">
				<div style="position: relative;width: 100%;">
					<h5 class='file_static shadow-2'>待评估：{{all_triple_list.length}} 已评估：{{true_number+false_number}}
						正确率：{{accuracy_text}}% 需评估：{{need_sample}}</h5>
				</div>
				<iframe style="margin-top: 35px;" ref='pdfjs_viewer_1' name="pdf_container" id="pdf_container"
					:src="'pdfjs/web/viewer.html?file='+pdf_path" frameborder="0" width="100%" height="710px"></iframe>
			</SplitterPanel>
			<SplitterPanel :size="35" style="overflow: scroll;padding:10px 0px 0px 10px;">
				<Menubar :model="nestedMenuitems">
				</Menubar>
				<div style="margin-top: 20px;">
					<div class="p-formgrid p-grid translation-item col-12"
						style="font-weight: 700;background-color:#f8f9fa;">
						<div class='md:col-1'>
							<i class="img-number">i</i>
						</div>
						<div class='md:col-3'>主语</div>
						<div class='md:col-3'>谓语</div>
						<div class='md:col-3'>宾语</div>
					</div>
					<div :style='list_style'>
						<div v-for="(item, j) of current_triple_list" :key="j">
							<div class='translation-item-2 col-12' :this_key='j'>
								<div class='md:col-1'>
									<i class="img-number">{{j+1}}</i>
								</div>
								<div class='md:col-3'>{{item.subject}}</div>
								<div class='md:col-3'>{{item.predicate}}</div>
								<div class='md:col-3'>{{item.object}}</div>
								<div v-if='j==0' class='md:col-1'>
									<i class="pi pi-check-circle" style="margin-top:auto;" title="正确"
										@click="tagTriple(j,true)" />
								</div>
								<div v-if='j==0' class='md:col-1'>
									<i class="pi pi-times-circle" style="margin-top:auto;" title="错误"
										@click="tagTriple(j,false)" />
								</div>
							</div>
						</div>
					</div>
				</div>

			</SplitterPanel>
		</Splitter>
	</div>
</template>

<script>
	import selectClassCard from './selectClassCard.vue';
	import ReadFileService from '../service/ReadFileService.js';
	import Utils from './Utils.vue';
	export default {
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {
			'selectClassCard': selectClassCard,
			'Utils': Utils,
		},
		data() {
			return {
				pdf_path: '../pdfs/高速公路交通出行网/sentence_1.pdf',
				all_style: {
					"height": "auto",
					"width": "100%",
				},
				list_style: {
					"height": "auto",
					"overflow-y": "auto",
					"background-color": "#ffffff",
				},
				corpus_list_style: {
					"height": "auto",
					"overflow-y": "auto",
					"background-color": "#ffffff",
				},
				// triple_list_style: {
				// 	"height": "auto",
				// 	"overflow-y": "auto",
				// 	"background-color": "#ffffff",
				// },
				word_list_style: {
					"width": "auto"
				},
				all_triple_list: [],
				original_triple_list: [],
				current_triple_list: [],
				true_number: 0,
				false_number: 0,
				accuracy: 0,
				accuracy_text:'0',
				need_sample: 0,

				nestedMenuitems: [{
						label: '语料',
						icon: 'pi pi-fw pi-folder-open',
						items: [{
								label: '导入语料',
								icon: 'pi pi-fw pi-inbox',
								command: () => {
									this.openFile('corpus');
								},
							},
							{
								label: '要素类别',
								icon: 'pi pi-fw pi-flag',
								items: [{
										label: '添加类别',
										icon: 'pi pi-fw pi-server',
										command: () => {
											this.openFile('add_class');
										},
									},
									{
										label: '替换类别',
										icon: 'pi pi-fw pi-sync',
										command: () => {
											this.openFile('fresh_class');
										},
									},
									{
										label: '默认类别',
										icon: 'pi pi-fw pi-flag',
										command: () => {
											this.loadDefaultClass();
										},
									},
								],
							},
						]
					},
					{
						label: '标注',
						icon: 'pi pi-fw pi-bookmark',
						items: [{
								label: '自动标注',
								icon: 'pi pi-fw pi-bolt',
								command: () => {
									this.cloudAutoTaged();
								},
							},
							{
								label: '自动抽取三元组',
								icon: 'pi pi-fw pi-paperclip',
								command: () => {
									this.autoExtractTripleList();
								},
							},
							{
								label: '加载标注',
								icon: 'pi pi-fw pi-tags',
								command: () => {
									this.readDataByFileName();
								},
							},
							{
								label: '保存Ctrl+S',
								icon: 'pi pi-fw pi-save',
								command: () => {
									this.saveDataToLocalAndCloud();
								},
							},
							{
								label: '导出标注',
								icon: 'pi pi-fw pi-arrow-circle-up',
								command: () => {
									this.outputEntity();
								},
							},
							{
								label: '清空标注',
								icon: 'pi pi-fw pi-trash',
								command: () => {
									this.clearEntityAndTriple();
								},
							},
						]
					},
					{
						label: '显示',
						icon: 'pi pi-fw pi-eye',
						items: [{
								label: '实体',
								icon: 'pi pi-fw pi-credit-card',
								command: () => {
									this.showItems('entity');
								},
							},
							{
								label: '元组',
								icon: 'pi pi-fw pi-link',
								command: () => {
									this.showItems('triple');
								},
							},
							{
								label: '选元组',
								icon: 'pi pi-fw pi-plus-circle',
								command: () => {
									this.show_triple_select = true;
									this.showItems('triple');
									this.resizeWin();
								},
							},
							{
								label: '统计',
								icon: 'pi pi-fw pi-chart-bar',
								command: () => {
									this.showItems('chart_bar');
								},
							},
							{
								label: '语料列表',
								icon: 'pi pi-fw pi-file',
								command: () => {
									this.showItems('corpus_list');
								},
							},
						]
					},
				],
			}
		},
		ReadFileService: null,
		created() {
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			// console.log('组件加载成功');
			var that = this;
			that.readTripleData();
			that.resizeWin();
			window.onresize = () => {
				return (() => {
					that.resizeWin();
				})();
			};
		},
		computed: {
			show_translate_card() {
				return this.$appState.show_translate_card;
			}
		},
		methods: {
			resizeWin() {
				var windowHeight = document.documentElement.clientHeight;
				this.all_style.height = windowHeight - 75 + 'px';
				if (this.show_item == 'triple' && this.show_triple_select == true) {
					this.list_style.height = windowHeight - 515 + 'px';
				} else {
					this.list_style.height = windowHeight - 205 + 'px';
				}
				this.corpus_list_style.height = windowHeight - 145 + 'px';
				// console.log(windowHeight - 158);
			},
			loadDefaultClass() {
				this.$refs.slectClassCard.readFileFromJson();
				this.$refs.Utils.showSusscessInfo('加载默认类别成功');
			},
			readTripleData() {
				this.ReadFileService.getTripleData().then(data => {
					this.all_triple_list = data;
					this.getRandomItems(10);
					this.pdf_path = '../' + this.current_triple_list[0]['pdf'];
					// console.log('三元组数据',data);
					// this.setCorpusInfo(data, '帮助文档.json');
					// this.item_class_list=[];
					// // this.saveClassToLocalstorage();
					// var that=this;
					// setTimeout(() => {
					// 	that.item_class_list=data.class_list;
					// 	that.saveClassToLocalstorage();
					// }, 1000)
				});
			},
			getRandomItems(item_number) {
				if (this.all_triple_list.length < item_number) {
					alert('样本数量不足');
					return [];
				}

				let randomList = [];
				for (let i = 0; i < item_number; i++) {
					let randomIndex = Math.floor(Math.random() * this.all_triple_list.length);
					let randomElement = this.all_triple_list[randomIndex];
					randomList.push(randomElement);
					this.all_triple_list.splice(randomIndex, 1);
				}

				this.current_triple_list = randomList;

				// console.log(randomList); // 输出随机选择的15个元素
				// console.log(this.all_triple_list); // 输出已从中删除15个元素的myList
			},
			tagTriple(j, is_true) {
				if (is_true == true) {
					this.true_number += 1;
				} else {
					this.false_number += 1;
				}
				this.current_triple_list.shift();
				this.getAccuracy();   //计算准确率
				this.getNeedSample(); //计算采样数量
				
				//如果没有样本了，则重新采样
				if (this.current_triple_list.length==0){
					this.getRandomItems(10);
				}
				this.pdf_path = '../' + this.current_triple_list[0]['pdf'];
			},
			getAccuracy() {
				this.accuracy = parseFloat((this.true_number / (this.true_number + this.false_number)).toFixed(2));
				this.accuracy_text = (this.true_number / (this.true_number + this.false_number) * 100).toFixed(2);
			},
			getNeedSample() {
				if (this.true_number==0 || this.false_number==0){
					this.need_sample='准确率错误，不可计算';
					return;
				}
				
				var e = 0.05;
				var z_alpha = 1.96;
				var t1=this.accuracy*(1-this.accuracy)*z_alpha*z_alpha
				var t2=t1/e;
				var t3=t2/e;
				this.need_sample=parseInt(t3)+1;
			}
		},
	}
</script>

<style scoped lang="scss">
	.img-number {
		float: left;
		// font-size: 18px;
		// margin-left: 5px;
		// font-weight: 700;
		// color:var(--primary-color);
		opacity: 0.5;
	}

	.pre_taged {
		background-color: var(--primary-color);
		border-radius: 3px;
		color: #FFFFFF;
	}

	.gis_class {
		background-color: #007BFF;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.gis_class_compound {
		background-color: #007BFF;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.time_class {
		background-color: #39b54a;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.time_class_compound {
		background-color: #39b54a;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.relation_class {
		background-color: #fbbd08;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.relation_class_compound {
		background-color: #fbbd08;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.topic_class {
		background-color: #e54d42;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.topic_class_compound {
		background-color: #e54d42;
		color: #FFFFFF;
		border-radius: 3px;
	}

	.translation-item {
		display: flex;
		align-items: center;
		border-top: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 {
		display: flex;
		align-items: center;
		// border-top: 1px solid #ededed;
		border-bottom: 1px solid #ededed;
		padding: 2px 0px 2px 0px;
	}

	.translation-item-2 i:hover {
		font-weight: 700;
		color: var(--primary-color);
	}

	.word-list {
		z-index: 3;
		position: absolute;
		float: left;
		width: auto;
		max-height: 140px;
		overflow-y: auto;
		overflow-x: auto;
		margin-top: 0.3125rem;
	}

	.word-item:hover {
		background-color: #1fa1fc;
		color: #ffffff;
	}

	.file_static {
		border-top-left-radius: 3px;
		padding: 5px;
		width: 100%;
		// max-width: 500px;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
		position: absolute;
	}
</style>
